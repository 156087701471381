
import {defineComponent} from "vue";
import {User} from "@/models/user/User";
import {UserService} from "@/services/UserService";
import {PhoneNumber} from "@/models/user/PhoneNumber";
import {bus} from "@/modules/eventBus";
import Swal from "sweetalert2";
import {PhoneNumberService} from "@/services/PhoneNumberService";
import {EmailAddressService} from "@/services/EmailAddressService";
import {EmailAddress} from "@/models/user/EmailAddress";
import {actionSheetController} from "@ionic/vue";
import {useToast} from "vue-toastification";
import {useUser} from "@/store/pinia/userStore";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  components: {Button},
  setup() {
    return {toast: useToast(), userStore: useUser()}
  },
  data() {
    return {
      isFetching: false,
      profile: {} as User,
    }
  },
  mounted() {
    this.fetch()
    bus.on('contactmethods:fetch', this.fetch)
    bus.on('modals:phoneverify:verified', this.fetch)
  },
  methods: {
    fetch() {
      this.isFetching = true
      UserService.fetch().then(response => {
        this.profile = response.data
      }).finally(() => {
        this.isFetching = false
      })
    },
    addPhone() {
      bus.emit('modals:phone:add')
    },
    addEmail() {
      bus.emit('modals:email:add')
    },
    requestPhoneVerification(phoneNumber: PhoneNumber) {
      bus.emit('modals:phoneverify:show', phoneNumber)
    },
    requestEmailVerification(emailAddressId: string) {
      EmailAddressService.requestVerification(emailAddressId).then(() => {
        Swal.fire('', 'Please check your email for a verification link.')
      })
    },
    removePhone(phoneNumberId: string) {
      this.isFetching = true
      PhoneNumberService.remove(phoneNumberId).then(() => {
        this.toast.success("Phone Number Removed")
      }).finally(() => {
        this.fetch()
        this.userStore.fetchUser()
        this.isFetching = false
      })
    },
    removeEmail(emailAddressId: string) {
      this.isFetching = true
      EmailAddressService.remove(emailAddressId).then(() => {
        this.toast.success("Email Address Removed")
      }).finally(() => {
        this.fetch()
        this.userStore.fetchUser()
        this.isFetching = false
      })
    },
    formatPhone(phoneNumber: string) {
      return phoneNumber.replace(/[^0-9]/g, '')
          .replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
    },
    makePhonePrimary(phoneNumber: PhoneNumber) {
      if (!phoneNumber.isPrimary && phoneNumber.isVerified) {
        this.isFetching = true
        PhoneNumberService.makePrimary(phoneNumber.phoneNumberId).then(() => {
          this.toast.success("Primary Phone Set")
        }).finally(() => {
          this.fetch()
          this.userStore.fetchUser()
          this.isFetching = false
        })
      }
    },
    makeEmailPrimary(emailAddress: EmailAddress) {
      if (!emailAddress.isPrimary && emailAddress.isVerified) {
        this.isFetching = true
        EmailAddressService.makePrimary(emailAddress.emailAddressId).then(() => {
          Swal.fire('All set!', 'The next time you login, use your new primary email address.')
        }).finally(() => {
          this.fetch()
          this.userStore.fetchUser()
          this.isFetching = false
        })
      }
    },
    showActionSheet(type: string, entity: any) {
      let showDelete = false
      let showVerify = false
      let showMakePrimary = false

      if (type === 'email') {
        showDelete = !(entity as EmailAddress).isPrimary
        showVerify = !(entity as EmailAddress).isVerified
        showMakePrimary = !(entity as EmailAddress).isPrimary && (entity as EmailAddress).isVerified
      } else {
        showVerify = !(entity as PhoneNumber).isVerified && (entity as PhoneNumber).canReceiveTexts
        showMakePrimary = !(entity as PhoneNumber).isPrimary && (entity as PhoneNumber).isVerified
        showDelete = true
      }

      actionSheetController
          .create({
            header: 'Manage Contact Method',
            buttons: [
              {
                text: 'Delete',
                role: 'destructive',
                id: 'delete-button',
                data: {
                  type: 'delete'
                },
                handler: () => {
                  Swal.fire('Oops, not yet!', 'Sorry, we don\'t yet support removing contact methods which can be used to login to your account. If you wish to remove this, contact support in the bottom-right corner, and we\'ll take care of it asap!');
                  /*if(type === 'email'){
                    this.removeEmail((entity as EmailAddress).emailAddressId)
                  } else {
                    this.removePhone((entity as PhoneNumber).phoneNumberId)
                  }*/
                },
                cssClass: showDelete ? '' : 'd-none'
              },
              {
                text: 'Make Primary',
                data: 10,
                handler: () => {
                  if (type === 'email') {
                    this.makeEmailPrimary((entity as EmailAddress))
                  } else {
                    this.makePhonePrimary((entity as PhoneNumber))
                  }
                },
                cssClass: showMakePrimary ? '' : 'd-none'
              },
              {
                text: 'Verify',
                data: 'Data value',
                handler: () => {
                  if (type === 'email') {
                    this.requestEmailVerification((entity as EmailAddress).emailAddressId)
                  } else {
                    this.requestPhoneVerification((entity as PhoneNumber))
                  }
                },
                cssClass: showVerify ? '' : 'd-none'
              },
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                },
              },
            ],
          }).then((actionSheet) => {
        actionSheet.present()
      })
    },
    showAddContactMethodActionSheet() {
      actionSheetController
          .create({
            header: 'Add New',
            buttons: [
              {
                text: 'Email Address',
                handler: () => {
                  this.addEmail()
                },
              },
              {
                text: 'Phone Number',
                handler: () => {
                  this.addPhone()
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                },
              },
            ],
          }).then((actionSheet) => {
        actionSheet.present()
      })
    },
  }
})
