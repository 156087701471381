
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import Button from "@/components/UI/Button.vue";
import {PhoneNumber} from "@/models/user/PhoneNumber";
import {PhoneNumberService} from "@/services/PhoneNumberService";
import {PhoneNumberCommand, VerifyPhoneCommand} from "@/models/user/PhoneNumberCommand";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import Swal from "sweetalert2";
import {AxiosError} from "axios";
import {useUser} from "@/store/pinia/userStore";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let entity = ref<PhoneNumber>({} as PhoneNumber);
    let verificationEntity = ref<VerifyPhoneCommand>({} as VerifyPhoneCommand);
    let mode = ref<string>('add');
    const invokeAdd = () => {
      entity.value = {} as PhoneNumber;
      mode.value = 'add';
      modal.value?.toggle();
    }
    const invokeVerify = (phoneNumberId: string, isAlreadyOpen = false) => {
      verificationEntity.value = {phoneNumberId: phoneNumberId} as VerifyPhoneCommand;
      mode.value = 'verify';
      if (!isAlreadyOpen) {
        modal.value?.toggle();
      }
    }
    const toggleClosed = () => {
      modal.value?.toggle();
    }
    const emitAdded = (contact: PhoneNumber) => {
      emit('added', contact)
      bus.emit('contactmethods:fetch')
      modal.value?.toggle();
    }
    const emitVerified = () => {
      emit('verified')
      modal.value?.toggle();
    }
    const userStore = useUser();
    return {
      modal,
      invokeAdd,
      entity,
      mode,
      toggleClosed,
      emitAdded,
      emitVerified,
      verificationEntity,
      invokeVerify,
      v$: useVuelidate(), userStore
    }
  },
  components: {InputGroup, Button, ModalBase},
  emits: ['added', 'verified'],
  mounted(){
    bus.on('modals:phone:add', this.invokeAdd);
    bus.on('modals:phone:verify', (payload) => {
      this.invokeVerify((payload as any).phoneNumberId as string, (payload as any).isAlreadyOpen as boolean)
    });
  },
  data() {
    return {
      isLoading: false,
      userPhone: ''
    }
  },
  validations() {
    return {
      entity: {
        number: {required}
      },
      verificationEntity: {
        verificationCode: {required}
      }
    }
  },
  methods: {
    add() {
      this.v$.entity.$touch()
      if (!this.v$.entity.$invalid) {
        this.isLoading = true
        PhoneNumberService.add({
          canReceiveTexts: this.entity.canReceiveTexts,
          isPrimary: false,
          number: this.entity.number
        } as PhoneNumberCommand).then(response => {
          this.isLoading = false
          if (this.entity.canReceiveTexts) {
            bus.emit('modals:phoneverify:show', response.data)
          }
          this.emitAdded(response.data)
          this.toggleClosed()
          this.userStore.fetchUser()
        }).catch((ex: AxiosError) => {
          console.log('exp', ex)
          this.isLoading = false
          Swal.fire('An Error Occurred', ex.response?.data.detail)
        })
      }
    },
    formatPhone(phoneNumber: string) {
      try {
        return phoneNumber.replace(/[^0-9]/g, '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      } catch {
        return ''
      }
    },
  },
  computed: {
    title(): string {
      return this.mode === 'add' ? 'Add New Phone Number' : `Verify ${this.formatPhone(this.entity.number)}`
    }
  }
})
