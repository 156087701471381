
import InputGroup from "@/components/UI/InputGroup.vue";
import {defineComponent} from "vue";
import {UserMeasurement} from "@/models/user/UserMeasurement";
import {MeasurementUnit} from "@/models/enums/MeasurementUnit";
import {integer} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {UserService} from "@/services/UserService";
import {isPlatform, toastController} from "@ionic/vue";
import {useToast} from "vue-toastification";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  components: {InputGroup},
  setup() {
    return {toast: useToast(), application: useApplication()}
  },
  data() {
    return {
      measurements: {unitSystem: 1} as UserMeasurement,
      isLoading: false
    }
  },
  validations: {
    measurements: {
      height: {integer},
      weight: {integer},
      chest: {integer},
      waist: {integer},
      hip: {integer},
      outseam: {integer},
      inseam: {integer},
      sleeveLength: {integer},
      neckSize: {integer},
      hat: {integer},
      shoulder: {integer},
      napeWaist: {integer},
      napeFloor: {integer},
      shoe: {integer}
    }
  },
  mounted() {
    this.fetch()
    bus.on('measurements:save', this.save)
  },
  unmounted() {
    bus.off('measurements:save', this.save)
  },
  methods: {
    fetch() {
      this.isLoading = true
      UserService.fetchMeasurements().then(response => {
        this.measurements = response.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    save() {
      this.isLoading = true
      UserService.upsertMeasurements(this.measurements).then(() => {
        if (isPlatform('capacitor')) {
          toastController.create({
            message: 'Measurements Saved!',
            duration: 2000
          }).then((I) => {
            I.present()
          });
        } else {
          this.toast.success("Measurements Saved")
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
  computed: {
    unitText(): string {
      return this.measurements.unitSystem === MeasurementUnit.Imperial ? 'inches' : 'cm'
    },
    weightUnitText(): string {
      return this.measurements.unitSystem === MeasurementUnit.Imperial ? 'lbs' : 'kilos'
    }
  }
})
