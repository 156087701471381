
import {defineComponent} from "vue";
import UserEmergencyContactsCard from "@/components/User/Settings/UserEmergencyContactsCard.vue";
import UserChangePasswordSettings from "@/components/User/Settings/UserChangePasswordSettings.vue";
import {alertController, isPlatform} from "@ionic/vue";
import Swal from "sweetalert2";
import {WebAnalytics} from "@/modules/webAnalytics";
import UserProfileSettings from "@/components/User/Settings/UserProfileSettings.vue";
import UserContactMethodSettings from "@/components/User/Settings/UserContactMethodSettings.vue";
import UserProfilePhotoSettings from "@/components/User/Settings/UserProfilePhotoSettings.vue";
import Button from "@/components/UI/Button.vue";
import {bus} from "@/modules/eventBus";
import {useApplication} from "@/store/pinia/applicationStore";
import {auth0} from "@/main";

export default defineComponent({
    setup() {
        return {application: useApplication()}
    },
    components: {
        Button,
        UserProfilePhotoSettings,
        UserContactMethodSettings,
        UserProfileSettings, UserChangePasswordSettings, UserEmergencyContactsCard
    },
    data() {
        return {
            section: 'main'
        }
    },
    methods: {
        signOut() {
            if (isPlatform('capacitor')) {
                alertController
                    .create({
                        header: 'Are you sure you wish to sign out?',
                        buttons: [
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'secondary',
                                id: 'cancel-button',
                                handler: () => {
                                },
                            },
                            {
                                text: 'Sign Out',
                                id: 'confirm-button',
                                handler: () => {
                                    WebAnalytics.logout();
                                    // Auth.Instance.signOut();
                                    auth0.logout({
                                        logoutParams: {
                                            returnTo: isPlatform('capacitor') ? process.env.VUE_APP_CAP_ENDSESSION : process.env.VUE_APP_AUTH_LOGOUT
                                        }
                                    });
                                },
                            },
                        ],
                    }).then(alert => {
                    alert.present()
                })
            } else {
                Swal.fire({
                    title: "Are you sure you wish to sign out?",
                    confirmButtonText: 'Sign Out',
                    showConfirmButton: true,
                    showCancelButton: true,
                    cancelButtonText: 'Cancel'
                }).then(response => {
                    if (response.isConfirmed) {
                        WebAnalytics.logout()
                        // Auth.Instance.signOut();
                        auth0.logout({
                            logoutParams: {
                                returnTo: isPlatform('capacitor') ? process.env.VUE_APP_CAP_ENDSESSION : process.env.VUE_APP_AUTH_LOGOUT
                            }
                        });
                    }
                })
            }
        },
        saveMeasurements() {
            bus.emit('measurements:save')
        },
        goToMeasurements() {
            this.$router.push({name: 'user-settings-measurements'})
        },
        setTab(tab: string) {
            this.section = tab
            this.application.setUserMobileSettingsTab(tab)
        },
        navigateToManageConflicts() {
            this.$router.push({name: 'user-settings-conflicts'})
        },
    },
    computed: {
        title(): string {
            switch (this.section) {
                case 'main':
                    return 'Profile & Settings'
                case 'personal':
                    return 'Personal Info'
                case 'photo':
                    return 'Photo'
                case 'password':
                    return 'Change Password'
                case 'emergency':
                    return 'Emergency Contacts'
                case 'contact':
                    return 'Contact Methods'
                case 'measurements':
                    return 'Measurements'
                default:
                    return ''
            }
        }
    }
})
