import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "h-250px p-0" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = {
  class: "table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9",
  "aria-label": "A list of all your email addresses and phone numbers."
}
const _hoisted_5 = { class: "fw-6 fw-bold text-gray-600" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "mb-1 text-gray-800" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  style: {"border-style":"none"}
}
const _hoisted_9 = {
  key: 0,
  class: "d-none badge badge-darkblue"
}
const _hoisted_10 = {
  key: 1,
  class: "badge badge-bloodred"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "mb-1 text-gray-800" }
const _hoisted_13 = {
  class: "d-flex justify-content-end",
  style: {"border-style":"none"}
}
const _hoisted_14 = {
  key: 0,
  class: "d-none badge badge-darkblue"
}
const _hoisted_15 = {
  key: 1,
  class: "badge badge-bloodred"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _createElementVNode("tbody", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profile.emailAddresses, (email) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: email.emailAddressId,
                onClick: ($event: any) => (_ctx.showActionSheet('email', email))
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(email.email), 1)
                ]),
                _createElementVNode("td", _hoisted_8, [
                  (email.isPrimary)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Primary"))
                    : _createCommentVNode("", true),
                  (!email.isVerified)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Not Verified"))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_6))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profile.phoneNumbers, (phone) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: phone.phoneNumberId,
                onClick: ($event: any) => (_ctx.showActionSheet('phone', phone))
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.formatPhone(phone.number)), 1)
                ]),
                _createElementVNode("td", _hoisted_13, [
                  (phone.isPrimary)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, "Primary"))
                    : _createCommentVNode("", true),
                  (!phone.isVerified && phone.canReceiveTexts)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, "Not Verified"))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_11))
            }), 128))
          ])
        ])
      ])
    ])), [
      [_directive_loading, _ctx.isFetching]
    ]),
    _createVNode(_component_Button, {
      class: "d-block d-md-none",
      onClick: _ctx.showAddContactMethodActionSheet
    }, {
      default: _withCtx(() => [
        _createTextVNode("Add New")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}