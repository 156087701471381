import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-md-4 col-lg-3" }
const _hoisted_4 = { class: "col-12 col-md-4 col-lg-3" }
const _hoisted_5 = { class: "col-12 col-md-4 col-lg-3" }
const _hoisted_6 = { class: "col-12 col-md-4 col-lg-3" }
const _hoisted_7 = { class: "col-12 col-md-4 col-lg-3" }
const _hoisted_8 = { class: "col-12 col-md-4 col-lg-3" }
const _hoisted_9 = { class: "d-flex flex-column col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormKit, {
      type: "form",
      modelValue: _ctx.profile,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profile) = $event)),
      actions: false,
      onSubmit: _ctx.save,
      id: "personalSettingsForm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FormKit, {
              type: "text",
              label: "First Name",
              name: "firstName",
              validation: "required"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FormKit, {
              type: "text",
              label: "Last Name",
              name: "lastName",
              class: "form-control",
              validation: "required"
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FormKit, {
              type: "text",
              label: "Pronouns",
              name: "pronouns",
              class: "form-control"
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FormKit, {
              type: "select",
              label: "Gender",
              name: "genderIdentity",
              options: _ctx.genderOptions
            }, null, 8, ["options"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FormKit, {
              type: "text",
              label: "Title",
              name: "title",
              class: "form-control"
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_FormKit, {
              type: "textarea",
              label: "Medical/Allergies",
              name: "medical",
              class: "form-control"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Button, { onClick: _ctx.savePersonalSettings }, {
              default: _withCtx(() => [
                _createTextVNode("save")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onSubmit"])
  ])), [
    [_directive_loading, _ctx.isLoading]
  ])
}