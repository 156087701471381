
import {defineComponent, ref} from "vue";
import {UserService} from "@/services/UserService";
import {ProblemDetails} from "@/models/ProblemDetails";
import {UploadFile, UploadProgressEvent, UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {ElMessage} from "element-plus";
import {useUser} from "@/store/pinia/userStore";
import {useToast} from "vue-toastification";
import Button from "@/components/UI/Button.vue";
import {bus} from "@/modules/eventBus";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button},
  setup(){
    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Avatar picture must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    const userStore = useUser();
    const toast = useToast();
    return {toast, userStore, handleAvatarSuccess, beforeAvatarUpload}
  },
  data() {
    return {
      avatarFile: {} as File,
      isLoading: false,
      hasAvatarQueued: false
    }
  },
  computed: {
    hasAvatar(): boolean {
      return this.userStore.profile.avatar !== '' && this.userStore.profile.avatar !== null
    },
  },
  mounted(){
    bus.on('modals:cropper:handle:mobile', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  methods: {
    requestCropper(xhr){
      bus.emit('modals:cropper:open', {blob: xhr.file, requester: 'modals:cropper:handle:mobile', isUserPhoto: true})
    },
    uploadPhoto(blob) {
      this.isLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      UserService.uploadPhoto(formData).then(() => {
        this.toast.success('Photo Uploaded')
        this.hasAvatarQueued = false
        this.userStore.fetchUser()
        WebAnalytics.trackFlexible("Uploaded Profile Photo", {});
      }).finally(() => {
        this.isLoading = false
      })
    },
    removePhoto() {
      this.isLoading = true
      UserService.removePhoto().then(() => {
        this.toast.success('Photo Removed')
        WebAnalytics.trackFlexible("Removed Profile Photo", {});
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.userStore.fetchUser()
        this.isLoading = false
      })
    },
  }
})
