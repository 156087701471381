import {apiClient} from "@/modules/apiClient";
import {AxiosResponse} from "axios";
import {EmailAddressCommand} from "@/models/user/EmailAddressCommand";
import {EmailAddress} from "@/models/user/EmailAddress";

export const EmailAddressService = {
    add(command: EmailAddressCommand): Promise<AxiosResponse<EmailAddress>>{
        return apiClient.post('/emailaddresses', command)
    },
    remove(emailAddressId: string): Promise<AxiosResponse>{
        return apiClient.delete(`/emailaddresses/${emailAddressId}`)
    },
    makePrimary(emailAddressId: string): Promise<AxiosResponse>{
        return apiClient.post(`/emailaddresses/${emailAddressId}/primary`)
    },
    requestVerification(emailAddressId: string): Promise<AxiosResponse>{
        return apiClient.get(`/emailaddresses/${emailAddressId}/verify`)
    },
    verify(verificationKey: string): Promise<AxiosResponse>{
        return apiClient.put(`/emailaddresses/verify?key=${verificationKey}`)
    }
}
