
import {User} from "@/models/user/User";
import {defineComponent} from "vue";
import {UserService} from "@/services/UserService";
import moment from "moment";
import {AccountCommand} from "@/models/user/AccountCommand";
import Button from "@/components/UI/Button.vue";
import { WebAnalytics } from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button},
  data() {
    return {
      isLoading: false,
      profile: {} as User,
      genderOptions: {
        0: 'Prefer Not To Say',
        1: 'Non-Binary',
        2: 'Man',
        3: 'Woman'
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.isLoading = true
      UserService.fetch().then(response => {
        this.profile = response.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    save() {
      this.isLoading = true
      UserService.update({
        medical: this.profile.medical,
        birthdate: this.profile.birthdate,
        firstName: this.profile.firstName,
        genderIdentity: this.profile.genderIdentity,
        lastName: this.profile.lastName,
        pronouns: this.profile.pronouns,
        title: this.profile.title
      } as AccountCommand).then(() => {
        //this.userStore.fetchUser()
      }).finally(() => {
        this.isLoading = false
        WebAnalytics.trackFlexible('Updated Proflie', {})
      })
    },
    savePersonalSettings() {
      this.$formkit.submit('personalSettingsForm')
    }
  },
  computed: {
    birthdateRaw: {
      get(): string {
        return moment(this.profile.birthdate).format('YYYY-MM-DD')
      },
      set(val) {
        this.profile.birthdate = moment(val).toDate()
      }
    },
  }
})
