
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import useVuelidate from "@vuelidate/core";
import InputGroup from "@/components/UI/InputGroup.vue";
import Button from "@/components/UI/Button.vue";
import {required, email} from "@vuelidate/validators";
import {EmailAddressCommand} from "@/models/user/EmailAddressCommand";
import { EmailAddress } from "@/models/user/EmailAddress";
import {EmailAddressService} from "@/services/EmailAddressService";
import Swal from "sweetalert2";
import {AxiosError} from "axios";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let entity = ref<EmailAddressCommand>({} as EmailAddressCommand);
    const invokeAdd = () => {
      entity.value = {} as EmailAddressCommand;
      modal.value?.toggle();
    }
    const toggleClosed = () => {
      modal.value?.toggle();
    }
    const emitAdded = (contact: EmailAddress) => {
      emit('added', contact)
      bus.emit('contactmethods:fetch')
      modal.value?.toggle();
    }
    return {
      modal,
      invokeAdd,
      entity,
      toggleClosed,
      emitAdded,
      v$: useVuelidate()
    }
  },
  components: {InputGroup, Button, ModalBase},
  emits: ['added'],
  mounted(){
    bus.on('modals:email:add', this.invokeAdd);
  },
  data() {
    return {
      isLoading: false,
    }
  },
  validations() {
    return {
      entity: {
        email: {required, email}
      }
    }
  },
  methods: {
    add(){
      this.v$.entity.$touch()
      if(!this.v$.entity.$invalid){
        this.isLoading = true
        EmailAddressService.add(this.entity).then(response => {
          this.isLoading = false
          this.emitAdded(response.data)
          Swal.fire('One Last Step!', 'Please check your email for a verification link.')
        }).catch((ex: AxiosError) => {
          console.log('exp', ex)
          this.isLoading = false
          Swal.fire('An Error Occurred', ex.response?.data.detail)
        })
      }
    }
  }
})
