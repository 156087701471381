
import {defineComponent, ref} from "vue";
import {User} from "@/models/user/User";
import InputGroup from "@/components/UI/InputGroup.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import {UserService} from "@/services/UserService";
import Button from "@/components/UI/Button.vue";
import Swal from "sweetalert2";
import {AccountCommand} from "@/models/user/AccountCommand";
import {PhoneNumberService} from "@/services/PhoneNumberService";
import PhoneNumberModal from "@/components/modals/PhoneNumberModal.vue";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import EmailAddressModal from "@/components/modals/EmailAddressModal.vue";
import {EmailAddressService} from "@/services/EmailAddressService";
import {PhoneNumber} from "@/models/user/PhoneNumber";
import {EmailAddress} from "@/models/user/EmailAddress";
import UserEmergencyContactsCard from "@/components/User/Settings/UserEmergencyContactsCard.vue"
import moment from "moment";
import {ElMessage} from 'element-plus'
import type {UploadFile, UploadProgressEvent, UploadRawFile,} from 'element-plus/es/components/upload/src/upload'
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";
import {actionSheetController, isPlatform} from '@ionic/vue';
import MeasurementSettings from "@/components/SelfContained/MeasurementSettings.vue";
import UserSettingsMenuMobile from "@/components/User/Settings/UserSettingsMenuMobile.vue";
import {useApplication} from "@/store/pinia/applicationStore";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {
    UserSettingsMenuMobile,
    MeasurementSettings,
    Button, Selector2, InputGroup, PhoneNumberModal, EmailAddressModal, UserEmergencyContactsCard
  },
  setup() {
    const phoneNumberModal = ref<InstanceType<typeof PhoneNumberModal>>();
    const emailAddressModal = ref<InstanceType<typeof EmailAddressModal>>();
    const invokeAddPhone = () => {
      phoneNumberModal.value?.invokeAdd();
    }
    const invokeVerifyPhone = (phoneNumberId: string) => {
      phoneNumberModal.value?.invokeVerify(phoneNumberId, false);
    }
    const invokeAddEmail = () => {
      emailAddressModal.value?.invokeAdd();
    }

    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Avatar picture must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    const userStore = useUser();
    const toast = useToast();
    return {
      phoneNumberModal,
      invokeAddPhone,
      invokeVerifyPhone,
      invokeAddEmail,
      emailAddressModal,
      v$: useVuelidate(),
      handleAvatarSuccess,
      beforeAvatarUpload,
      userStore,
      toast, application: useApplication()
    }
  },
  data() {
    return {
      profile: {} as User,
      avatarFile: {} as File,
      showAvatarCropper: false,
      genderOptions: [
        {
          text: 'Prefer Not To Say',
          id: 0
        },
        {
          text: 'Non-Binary',
          id: 1
        },
        {
          text: 'Man',
          id: 2
        },
        {
          text: 'Woman',
          id: 3
        }
      ],
      isLoading: false,
      isFetching: true,
      photoIsLoading: false,
      hasAvatarQueued: false,
      contactMethodsLoading: false,
      emergencyContactsLoading: false
    };
  },
  computed: {
    birthdate: {
      get(): string {
        return moment(this.profile.birthdate).format('YYYY-MM-DD')
      },
      set(val) {
        this.profile.birthdate = moment(val).toDate()
      }
    },
    hasAvatar(): boolean {
      return this.profile.avatar !== '' && this.profile.avatar !== null
    },
    isCapacitor(): boolean {
      return isPlatform('capacitor')
    }
  },
  mounted() {
    this.fetch()
    bus.on('modals:phoneverify:verified', this.fetch)
    bus.on('modals:cropper:handle:desktop', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  validations() {
    return {
      profile: {
        firstName: {required},
        lastName: {required},
        pronouns: {required},
        title: {required},
        birthdate: {required}
      }
    }
  },
  methods: {
    invokeAddEmergencyContact() {
      bus.emit('modals:emergency:add')
    },
    fetch() {
      this.isFetching = true
      UserService.fetch().then(response => {
        this.profile = response.data
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.isFetching = false
      })
    },
    update() {
      this.v$.profile.$touch()
      if (!this.v$.profile.$invalid) {
        this.isLoading = true
        UserService.update({
          medical: this.profile.medical,
          birthdate: this.profile.birthdate,
          firstName: this.profile.firstName,
          genderIdentity: this.profile.genderIdentity,
          lastName: this.profile.lastName,
          pronouns: this.profile.pronouns,
          title: this.profile.title
        } as AccountCommand).then(() => {
          this.toast.success('Profile Updated')
          this.userStore.fetchUser()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isLoading = false
          WebAnalytics.trackFlexible('Updated Profile', {})
        })
      }
    },
    requestCropper(xhr) {
      bus.emit('modals:cropper:open', {blob: xhr.file, requester: 'modals:cropper:handle:desktop', isUserPhoto: true})
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      UserService.uploadPhoto(formData).then(response => {
        this.toast.success('Photo Uploaded')
        this.hasAvatarQueued = false
        this.userStore.fetchUser()
        this.profile.avatar = response.data.avatarUrl
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.photoIsLoading = false
        WebAnalytics.trackFlexible('Uploaded Profile Photo', {})
      })
    },
    removePhoto() {
      this.photoIsLoading = true
      UserService.removePhoto().then(() => {
        this.profile.avatar = ""
        this.toast.success('Photo Removed')
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.userStore.fetchUser()
        this.photoIsLoading = false
        WebAnalytics.trackFlexible('Removed Profile Photo', {})
      })
    },
    addPhone() {
      this.invokeAddPhone()
    },
    requestPhoneVerification(phoneNumber: PhoneNumber) {
      bus.emit('modals:phoneverify:show', phoneNumber)
    },
    requestEmailVerification(emailAddressId: string) {
      EmailAddressService.requestVerification(emailAddressId).then(() => {
        Swal.fire('', 'Please check your email for a verification link.')
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      })
    },
    removePhone() {
      Swal.fire('Oops, not yet!', 'Sorry, we don\'t yet support removing contact methods which can be used to login to your account. If you wish to remove this, contact support in the bottom-right corner, and we\'ll take care of it asap!');
      /*this.contactMethodsLoading = true
      PhoneNumberService.remove(phoneNumberId).then(() => {
        this.toast.success("Phone Number Removed")
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.fetch()
        this.userStore.fetchUser()
        this.contactMethodsLoading = false
      })*/
    },
    removeEmail() {
      Swal.fire('Oops, not yet!', 'Sorry, we don\'t yet support removing contact methods which can be used to login to your account. If you wish to remove this, contact support in the bottom-right corner, and we\'ll take care of it asap!');
      /* this.contactMethodsLoading = true
       EmailAddressService.remove(emailAddressId).then(() => {
         this.toast.success("Email Address Removed")
       }).catch(err => {
         let errorDetails = err.response.data as ProblemDetails
         this.toast.error(errorDetails.detail)
       }).finally(() => {
         this.fetch()
         this.userStore.fetchUser()
         this.contactMethodsLoading = false
       })*/
    },
    formatPhone(phoneNumber: string) {
      return phoneNumber.replace(/[^0-9]/g, '')
          .replace(/(\d)(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
    },
    makePhonePrimary(phoneNumber: PhoneNumber) {
      if (!phoneNumber.isPrimary && phoneNumber.isVerified) {
        this.contactMethodsLoading = true
        PhoneNumberService.makePrimary(phoneNumber.phoneNumberId).then(() => {
          this.toast.success("Primary Phone Set")
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.fetch()
          this.userStore.fetchUser()
          this.contactMethodsLoading = false
        })
      }
    },
    makeEmailPrimary(emailAddress: EmailAddress) {
      if (!emailAddress.isPrimary && emailAddress.isVerified) {
        this.contactMethodsLoading = true
        EmailAddressService.makePrimary(emailAddress.emailAddressId).then(() => {
          Swal.fire('All set!', 'The next time you login, use your new primary email address.')
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.fetch()
          this.userStore.fetchUser()
          this.contactMethodsLoading = false
        })
      }
    },
    showActionSheet() {
      if (isPlatform('capacitor')) {
        actionSheetController
            .create({
              header: 'Manage Contact Method',
              buttons: [
                {
                  text: 'Delete',
                  role: 'destructive',
                  id: 'delete-button',
                  data: {
                    type: 'delete'
                  },
                  handler: () => {
                    console.log('Delete clicked')
                  },
                },
                {
                  text: 'Make Primary',
                  data: 10,
                  handler: () => {
                    console.log('Share clicked')
                  },
                },
                {
                  text: 'Verify',
                  data: 'Data value',
                  handler: () => {
                    console.log('Play clicked')
                  },
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () => {
                    console.log('Cancel clicked')
                  },
                },
              ],
            }).then((actionSheet) => {
          actionSheet.present()
        })
      }
    },
    showAddContactMethodActionSheet() {
      actionSheetController
          .create({
            header: 'Add New',
            buttons: [
              {
                text: 'Email Address',
                handler: () => {
                  this.invokeAddEmail()
                },
              },
              {
                text: 'Phone Number',
                handler: () => {
                  this.invokeAddPhone()
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                },
              },
            ],
          }).then((actionSheet) => {
        actionSheet.present()
      })
    },
    saveMeasurements() {
      bus.emit('measurements:save')
    }
  }
})
